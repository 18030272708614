// nav

.navbar {
 margin: auto;
}

.navbar-tab {
  position: absolute;
  bottom: 0;
}

.navbar-dark .navbar-nav .nav-link {
  color: $black;

  &:hover {
    color: $black;
  }
}

.navbg {
  color: $white;
  background-color: $header-color;
  z-index: 4;
  margin: auto;
  .nav-content {
    min-height: 80px;
    margin: 0 auto;
    padding: 0;
    max-width: 1246px;
  }
}

.navbar-brand {
  padding: 16px 0px !important;
  img {
    object-fit: contain;
    width: 140px;
    height: 47px;
  }
}
.user-icon{
  background-color: $white;
  width: 32px;
  height: 32px;
  font-size: 32px;
  display: flex; /* or inline-flex */
  align-items: center; 
  justify-content: center;
  border-radius: 50%;
}
.user-label{
  font-family: $primary-font;
  color: $header-color;
  font-size: 14px;
  margin: auto;
  font-weight: 700;
}
 .nav-right{
  position: absolute;
  bottom: 17px;
  right: 0;
  .cart-wrapper{
    position: relative;
    margin-right: 44px;
    button{
      background-color: transparent;
      border: none;
    }
    .icon{
      color: $white;
    }
    span{
      font-size: 12px;
      line-height: 14px;
      color: $white;
      position: absolute;
      border: solid $cart-red;
      border-radius: 50%;
      margin: auto;
      min-width: 20px;
      min-height: 20px;
      width: auto;
      height: auto;
      background: $cart-red;
      top: -8px;
      right: -8px; 
    }
  }
 }
.dropdown-toggle.nav-link{
  padding: 0px !important;
}

.nav-shadow {

  @media (max-width: 567px) {
    box-shadow: 0 0;
  }
}

.pat-leafio-logo {
  background-color: transparent;
  height: 68px;
}

.nav-link {
  color: $white;
  padding: 0px 0px 14px 0px !important;
  padding-right: 40px;
  padding-bottom: 14px;
}

.navbar-nav+.nav-item :hover {
  color: $white;
  background-color: $white;
}

.nav-link i {
  color: $white;
}

.nav-link-text {
  color: $white;
  font-size: 18px;
  line-height: 21px;
}

.navbar-tab {
  .nav-item {
    position: relative;
    margin-right: 32px;
  }
}

.nav-link.active {
  .nav-link-text {
    font-weight: bold;
  }
}

.nav-link.active:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: $white;
}

.navbar__link--active {
  .nav-link-text {
    color: $white;
  }
}

.nav-link-text:hover {
  color: $white;
}

.navbar-nav .nav-link a::after {
  border-bottom: 5px solid $primary;
  bottom: -10px;
  content: " ";
  left: 0;
  position: absolute;
  right: 0;
}

.navbar-light .navbar-nav .active::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: " ";
  border-bottom: 5px solid $primary;
}

.desUserIcon {
  cursor: pointer;
}

.drpbtn {
  position: relative;
}

.dropdown {
  user-select: none;

  .mobUserIcon {
    display: none;
  }
}

@media (max-width: 567px) {
  .dropdown {
    .desUserIcon {
      display: none;
    }

    .mobUserIcon {
      display: block;
      width: 45px;
      height: 45px;
    }
  }
}

// PROJECT STYLING

$border-radius: 3px;
$active: $black;
$animation: pulse;

@media (min-width: 990px) and (max-width: 1338px) {
  .nav-response {
    margin-left: 1px !important;
  }
}

@media (min-width: 1338px) and (max-width: 1550px) {
  .nav-response {
    margin-left: 8px !important;
  }
}

.welcome-text {
  margin-left: 120px;
}

.dropbtn {
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  background-color: $white;
  min-width: 150px;
  z-index: 1;
  right: 10px;
}

.dropdown-content a {
  padding: 12px;
  text-decoration: none;
  display: block;

  &:hover {
    background-color: initial;
    background: darken($primary, 5%);
  }
}

.dropdown>.nav-item {
  color: $grey;
}

.dashboard-cards {
  .card {
    width: 175px;
    height: 150px;
  }

  .card-body {
    display: flex;
    justify-content: center;
    align-items: center;

    .total-txt,
    h5 {
      font-size: 16px;
    }
  }
}

nav ul ul {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  list-style: none;
  top: 35px;
}

nav ul li:hover>ul {
  display: inherit;
}

nav ul ul li {
  width: 200px;
  float: none;
  position: relative;
  color: $grey;
  padding-left: 10px;
}

@media (min-width: 768px) {
  .pat-mob-profile {
    display: none;
  }
}


.dropdown-toggle::after {
  display: none !important;
}