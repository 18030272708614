.lds-dual-ring {
  width: 40px;
  height: 100%;
  right: -7px;
  top: 3px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 6px solid $white;
  border-color: $secondary transparent $secondary transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-secondary {
  .lds-dual-ring {
    padding-left: 8px;
  }
  .lds-dual-ring:after {
    width: 22px;
    height: 22px;
    border-color: $white transparent $white transparent;
  }
}
