$theme-colors: (
  "primary": #364BAA,
  "secondary": #FFFFFF,
);

.hide {
  list-style: none;
}

$primary-font: "HelveticaNeue-Regular", "Helvetica Neue Regular", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$secondary-font: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$pharmacist-primary-font: "HelveticaNeue-Bold", "Helvetica Neue Bold", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$pharmacist-secondary-font: "HelveticaNeue-Regular", "Helvetica Neue Regular", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font-thin: "HelveticaNeue-Thin", "Helvetica Neue Thin", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$primary:#000000;
$secondary:#364BAA;
$dark-blue:#212437;
$dark-blue-label:#243271;
$link-blue: #002EFF;
$light-blue:#F0F3FF;
$white:#FFFFFF;
$black: #000;
$grey: #ECECEC;
$light-grey: #F0F3FF;
$leafio-grey:#707070;
$leafio-mild-grey: #747474;
$leafio-border-grey: #e6e6e6;
$instock: #b5a100;
$grey-shade: #dddddd;
$milky-white: #f8f9fa;
$traffic-yellow: #ed8b26;
$red: rgb(219, 20, 20);
$cart-red:#C40000;
$green: #388e3c;
$pharmacist-login-grey: #e5e5e5;
$scroll-track: #00000029;
$scroll-thumb: #A5A5A5;
$placeholder: #c6c6c6;
$border-grey: #ced4da;
$date-picker-disable: #CCCCCC;
$textarea-description: #7d7d7d;
$text-background: #e2e2e2;
$success-text: #198754;
$border-divider: #00000085;

$primary-color: #364BAA;
$header-color:#000E4B;
// Media quey BreakPoint
$small: 576px;
$medium: 720px;
$large: 960px;
$extra-large: 1140px;

// Nav
$navbar-brand-margin-end:  5rem;
$navbar-brand-padding-y:1rem;
$navbar-nav-link-padding-x:0px;
$navbar-dark-hover-color: $white;
$navbar-dark-active-color: $white;

// Card
$card-border-radius:  0px;
$card-spacer-x: 10px;

// List-group
$list-group-item-padding-y: 0px;
$list-group-item-padding-x: 0px;

//  Font-Size
 $font-xx-small:12px;
 $font-x-small:14px;
 $font-small:16px;
 $font-medium:20px;
 $font-large:24px;
 $font-x-large:32px;

@font-face {
 font-family: 'Sofia Pro';
 font-style: normal;
 font-weight: 400;
 src: local('Sofia Pro'), url('https://fonts.cdnfonts.com/s/14903/Sofia Pro Regular Az.woff') format('woff');
}
