
.drawer {
  // width: 0;  -----> use this as style prop in the element to have control in React
  position: fixed; /* Stay in place */
  z-index: 1060;
  top: 0;
  background-color: $milky-white;
  overflow-x: hidden; 
  transition: ease-out 0.5s;
  .content {
    padding: 16px;
  }
}
