@import "./variables";

* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  max-width: 100%;
  font-family: $primary-font;
  font-size: 14px;
  overflow-x: hidden;
  overflow-y: unset !important;
  color: $primary;
  ;
}

.select-field {
  min-width: 150px;
}

.select-field-grroup-collapsed {
  display: none;
}

.group-heading-wrapper:after {
  content: "";
  margin: 5px 0px;
  display: inline-block;
  border: 7px solid transparent;
  border-top: 8px solid gray;
  border-bottom: 0 none;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.pos-fix {
  position: fixed;
}

.leafio-logo {
  max-width: 183px;
  object-fit: contain;
  display: inline-block;
  width: 183px;
  height: 68px;
}
.modal.pharmacy-form-modal {
  top: 20px;
  .pharmacy-update-true {
    max-height: 600px;
    overflow-y: scroll;
    .form-disabled-true {
    input {
      color: #808080;
    }
    label, svg, ::placeholder, div {
      color: #d3d3d3;
    }
  }
  }
}


.leafio-logo.ablt {
  position: absolute;
  top: 20px;
  left: 60px;
}

[class*="-singleValue"] div {
  display: flex;
  flex-direction: column;
}

.pat-customize {
  @media (max-width: 567px) {
    display: flex;
    margin-left: 6px;
  }

  .pat-reset-pass {
    background-color: #ee981c;
    border-radius: 10px;
    color: #fff;
    text-transform: uppercase;
    font-family: "Sofia Pro", sans-serif;
    padding: 8px 0 5px 0;
    font-weight: bold;
    width: 200px;

    @media (max-width: 567px) {
      font-size: 12px;
      width: 140px;
    }

    @media (max-width: 340px) {
      width: 135px;
    }
  }

  .pat-reset-pass-cancel {
    background-color: $white;
    color: $secondary;
    text-transform: uppercase;
    border: 1px solid $secondary;
    border-radius: 10px;

    @media (max-width: 567px) {
      font-size: 12px;
    }
  }
}

.auth-container,
.center-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .sign-in {
    max-width: 400px;
    min-width: 320px;
  }

  .sign-out {
    max-width: 475px;
    min-width: 320px;
  }
}

.font-light {
  font-family: $secondary-font  !important;
  font-size: 16px;
}

//validation error-message styles
.srv-validation-message {
  color: $red;
  margin-top: 0.5em;
  font-size: 0.75em;
  text-align: left;
}

.note-highlight {
  color: $red;
}

.auth-wrapper {
  max-width: 475px;
}

// header
.header {
  overflow: hidden;
}

.header a.active {
  background-color: $secondary;
  color: $white;
}

.mt-6 {
  margin-top: 6rem;
}

.mt-8 {
  margin-top: 8rem;
}

@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }

  .footer {
    background-color: $header-color;
    font-size: 12px;
    color: $white;
    margin-top: auto;
    padding: 0px 60px;
    bottom: 0px;
    text-align: center;
  }
}

.footer {
  background-color: $header-color;
  color: $white;
  min-height: 20px;
  font-size: 10px;
  line-height: 12px;
  margin: 0 auto;
  bottom: 0px;
  position: fixed;
  z-index: 3;
  .wrap {
    max-width: 1246px;
    margin: auto;
  }

  @media (max-width: 1024px) {
    min-height: 30px !important;
  }


  // z-index: 1;
}

a {
  outline: none;
}

.btn-l-2 {
  margin-left: 0.8rem;
}

.Px-4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.sub-text {
  font-size: 14px;
  font-family: $secondary-font;
  font: weight 500;
  color: black;
}

.dashboard-btn {
  background-color: $secondary;
  color: $white;
  border: 2px solid $secondary;
}

// table filter spacing in between
.filter-input {
  margin-bottom: 10px;
}

.clear-filter {
  margin-top: -4px !important;
}

.btn-imp-upld {
  top: 45px;
}

@media (max-width: 567px) {
  .leafio-logo {
    padding: 0;
    max-width: 140px;
  }
}

@media (max-width: 350px) {
  .footer {
    padding: 0px;
    font-size: 14px;
  }

  .leafio-logo {
    padding: 0;
    max-width: 100px;
  }
}

@media (max-width: 690px) {
  .order-button {
    float: left !important;
  }
}

.close.clear-filter {
  cursor: pointer;
}

.add-option {
  color: $secondary;
  cursor: pointer;
}

.export-btn {
  z-index: 200;
}

.text-gray {
  color: gray;
}

// forgot password page full responsive
@media (min-width: 320px) and (max-width: 950px) {
  .forgot-response {
    right: -31% !important;
  }
}

.warning-color {
  color: red;
}

.filterable-table-container {
  .header-row {
    margin-top: 6px;

    & .exact-filters {
      margin-top: 20px;
    }

    & .record-count {
      padding-top: 7px;
    }
  }

  table.filterable-table thead tr th {
    white-space: nowrap;
    user-select: none;

    & span.fa {
      padding-left: 8px;
      color: $grey;
    }
  }

  table.filterable-table tfoot tr td {
    font-weight: 700;
  }

  .filter-container {
    position: relative;
  }

  .clear-filter {
    position: absolute;
    right: 5px;
    top: 1px;
  }

  span.sortable,
  span.filterable {
    cursor: pointer;
  }

  span.filterable {
    border-bottom: 1px solid $secondary;
  }

  ul.pagination {
    & li a {
      user-select: none;
    }

    & li:not(.disabled) a {
      cursor: pointer;
    }
  }

  .pagination>.active>a,
  .pagination>.active>a:focus,
  .pagination>.active>a:hover,
  .pagination>.active>span,
  .pagination>.active>span:focus,
  .pagination>.active>span:hover {
    z-index: 3;
    color: $white;
    cursor: default;
    background-color: $secondary;
    border-color: $secondary;
  }

  span.empty {
    font-style: italic;
    color: $grey;
  }

  .filter-item {
    border: 1px solid $grey;
    border-radius: 1px;
    display: inline-block;
    margin-right: 15px;

    & .filter-item-title {
      background-color: $primary;
      border-radius: 1px;
      color: $white;

      & .filter-item-remove {
        color: $white;
        font-weight: 700;
        user-select: none;
        padding-right: 10px;
        cursor: pointer;
      }
    }

    & .filter-item-title,
    & .filter-item-value {
      padding: 5px 10px;
      display: inline-block;
    }
  }
}

.dashboard-link {
  color: $secondary;

  &:hover {
    color: $secondary;
  }
}

.sas-border {
  border: 1px solid $primary;
  border-radius: 0.25rem;
}

.rounded.dropdown-border {
  border: 1px solid red;
  border-radius: 3px !important;
}

.no-list-style {
  list-style: none;
}

.gp-code {
  font-size: 12px;
}

.invoice-text {
  font-size: 12px;
  color: $secondary;
}

.preferred-pharmacy-order {
  background: $primary;

  &:hover {
    background-color: $primary  !important;
  }
}

.priority-based-order {
  background: #cdffdd;

  &:hover {
    background-color: #dfffe9 !important;
  }
}

.prescriptionIcon {
  width: 28px;
  padding: 4px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.prescriptionIconDisabled {
  width: 28px;
  padding: 4px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.4;
  cursor: not-allowed;
}

.align-center {
  text-align: center;
}

.invoice-edit {
  font-size: 12px;
  color: $secondary;
}

.main-content {
  position: relative;
  max-width: 1246px;
  top: 75px;
  padding:25px 0px!important;
  margin:auto !important;

}

.custome-footer {
  margin-left: 1.5rem;

  @media (max-width: 350px) {
    .custome-footer {
      margin-left: 0;
    }
  }
}

.cpd-banner {
  background-image: url("../assets/images/leafio-banner.jpg");
  background-size: cover;
  background-position: center;
  height: 270px;
}

.cpd-transform {
  background-color: rgba($color: #000000, $alpha: 0.1);
  width: 100%;
  height: 100%;
}

.resources-content {
  margin: -40px -10px 0 -10px;
}

.resource-card {
  width: 400px;
  height: 300px;

  hr.res-short-line {
    width: 75px;
  }

  .card-footer {
    border-width: 0px;
    background-color: transparent;
  }
}

.font-bold {
  font-family: $primary-font;
  font-size: 16px;
}

.add-product {
  right: 5%;
}

.inactive-display-true {
  opacity: 0.4;
}

.lowinstock {
  background-color: $text-background;
  font-size: 14px !important;
  color: $black !important;
  margin-left: 5% !important;
  font-weight: 500 !important;
}

.success-label{
  color:$green;
}

.product-wrapper {
  width: 45%;

  p {
    margin: 0;
    line-height: 1.6em;
  }

  a {
    color: $green;

    &:hover,
    &:focus,
    &:active {
      color: $green;
      text-decoration: none;
    }
  }

  .product-item {
    border-radius: 20px;

    .title {
      font-size: 24px;
      letter-spacing: -0.05em;
      color: $green;
      font-weight: 600;
    }

    .desc-text {
      font-weight: 600;
    }

    .img-wrapper {
      .product-image {
        width: 87px;
        height: 184px;
        object-fit: contain;
      }

      .product-image-small {
        height: 184px;
        width: 120px;
        object-fit: contain;
        margin-left: 8px;
      }

      .img-text {
        position: absolute;
        bottom: 0;
        width: fit-content;
        font-size: 14px;
        color: $primary;
      }
    }
  }

  .desc-footer p {
    color: $primary;
    line-height: 2em;
  }
}

.pricingGuaranteeText {
  font-family: $secondary-font  !important;
  color: #174758;
}

.doctor-products-list {
  .rdl-control {
    min-height: 200px;

    option {
      margin-top: 5px;
      margin-bottom: 5px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.icon-circle-wrap {
  border-radius: 80px;
  width: 160px;
  height: 160px;
  border: 3px solid $primary-color;
  line-height: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary-color;

  &::before {
    height: unset;
    width: unset;
  }
}

.icon-circle-wrap.welcome-wrap {
  width: 101px;
  height: 101px;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.btn.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
}

.login-wrapper {
  a {
    color: $secondary;
    text-decoration: underline;
  }
}

.px-0-small {
  @media (max-width: $small) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.flex-direction-col {
  flex-direction: column;
}

.prefixed-input {
  position: relative;

  .prefix {
    position: absolute;
    left: 25px;
    bottom: 26px;
    color: grey;
    z-index: 1000;
  }

  .prefix.error {
    bottom: 48px;
  }

  input.has-prefix {
    padding-left: 30px;
  }
}

.star-rating {
  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  span {
    color: #ffd73d;
  }
}

@mixin btnStyle {
  text-transform: uppercase;
  font-family: $pharmacist-secondary-font;
  padding: 8px 0 5px 0;
  font-weight: bold;
  width: 150px;
}

@mixin formBaseStyle {
  width: 150px;
  height: 150px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;

  .fa {
    color: $white;
  }

  .file-name {
    color: $green;
  }
}

.file-upload {
  @include formBaseStyle;
  border: 1px solid $white;

  label {
    cursor: pointer;
  }
}

.file-upload-error {
  @include formBaseStyle;
}

.file-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px;
}

@mixin margin-x ($left, $right) {
  margin-left: $left;
  margin-right: $right;
}

.collapse-button {
  display: block !important;
  font-weight: bold !important;
  font-size: 14px !important;
  color: #000000 !important;
}

.collapse-content.collapsed {
  display: none;
}

.collapsed-content.expanded {
  display: block;
}

.vertical-line {
  width: 1px;
  background-color: #e7e7e7;
  height: 70%;
  float: right;
  margin-top: 10px;
}

.horizontal-line {
  width: 100%;
  background-color: #e7e7e7;
  height: 1px;
  opacity: 0.4;
  margin-top: 10px;
}

.parent {
  height: 100px;
  width: 150px;
  position: relative;

  .child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    .count {
      margin-bottom: 0 !important;
      font-size: 18px;
      font-weight: bold;
    }

    .text {
      font-size: 10px;
      color: #3f3f3f;
      margin-bottom: 0 !important;
    }

    .color-delivered {
      color: #038b25
    }

    .color-progress {
      color: #b5a101
    }

    .color-cancelled {
      color: #db0001
    }
  }
}

#navbarNavDropdown {
  margin-top: 60px;

  .nav-item {
    font-weight: 500;

    .active {
      border-bottom: 3px solid #000000;
      font-weight: bold;
    }
  }
}

// custom scroll
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $scroll-track;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px $scroll-thumb;
}

.Toastify__toast-container {
  word-break: break-word;
}

.model-title {
  font-weight: bold;
  font-family: $pharmacist-primary-font;
  font-size: 24px;
  line-height: 29px;
}

.pwd-strength-bar {
  div {
    height: 5px !important;
    border-radius: 4px;
  }
}

.react-datepicker-popper {
  z-index: 10000 !important;
}

.datePicker {
  .wrapper {
    min-width: 170px;
    position: relative;

    i.icon {
      position: absolute;
      top: 17px;
      font-size: 23px;
      right: 20px;
    }
  }
}

a.link {
  color: $secondary  !important;
  text-decoration: underline !important;
}


// Utils Style
.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.num-formater {
  color: $secondary;
  font-size: 32px;
}

.form-title {
  color: $secondary;
}

.form-group {
  margin-bottom: 10px;
}

.btn-primary {
  background-color: $white  !important;
  color: $secondary  !important;
  border-color: $secondary  !important;
}

.btn-secondary {
  color: $white  !important;
  background-color: $secondary  !important;
  border-color: $secondary  !important;
}

.btn-upload {
  color: $primary  !important;
  background-color: $grey  !important;
  border-color: $grey  !important;
}

.color-secondary {
  color: $secondary;
}

.nodataText {
  font-size: 16px;
}

.f12 {
  font-size: $font-xx-small;
}

.f14 {
  font-size: $font-x-small;
}

.f16 {
  font-size: $font-small;
}

.f20 {
  font-size: $font-medium;
}

.f24 {
  font-size: $font-large;
}

.view-cart-btn {
  z-index: 5;
  width: 100%;
  right: 60px;
  margin-bottom: 30px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.w-fc {
  width: fit-content !important;
}

.text-underline-hover {
  text-decoration: underline  !important;
  color: $link-blue;
  font-size: 12px;

  &:hover {
    color: $link-blue;
    text-decoration: underline !important;
    cursor: pointer;
  }
}

.blue-text {
  color: $secondary;
  font-weight: 800;
  &:hover {
    cursor: pointer;
  }
}

// responsive media query
@media (min-width: 1024px) and (max-width:1366px) {
  .view-cart-btn {
    top: 110px
  }
}

@media (min-width: 1366px) and (max-width:1920px) {
  .view-cart-btn {
    top: 130px
  }
}

@media (min-width: 1920px) {
  .view-cart-btn {
    top: 150px
  }
}

// Consignment Detail

.consignment-id {
  color: $primary-color;
  font-weight: bold;
  font-size: 17px;
}

.consignment-date {
  min-width: 120px;
  text-align: right;
}

.consignment-pharmacy {
  min-width: 120px;
}

// product catalog and details page
svg.search-icon {
  top: 10px;
  right: 20px;
  font-size: 15px;
  z-index: 5;
  cursor: pointer;
}

svg.search-icon::after {
  border-color: $black transparent $black transparent
}

.card-wrap {
  cursor: pointer;
  margin-bottom: 30px;
  .img-wrap {
    min-height: 240px;
    max-height: 240px;
    border: 1px solid $grey;
    object-fit: contain;
  }

  .card-title {
    padding: 20px 0px 15px 0px;
    font-size: 20px;
    line-height: 25px;
    font-weight: bold;
  }

  .card-sub-label {
    font-size: 14px;
    line-height: 16px;

  }

  .card-sub-label.out-stock {
    color: $red;
  }

  .product-card-status {
    width: 55px;
    object-fit: contain;
    right: 10px;
    top: 10px;
  }

  .coming-soon-sticker {
    object-fit: contain;
    left: -10px;
  }

  .product-type-wrap {
    width: 45px;
    height: 45px;
    border-radius: 22.5px;
    border: 1px solid $grey;
    vertical-align: middle;
    right: 20px;
    bottom: 20px;
  }

  .product-type {
    width: 19px;
    height: 24px;
    object-fit: contain;
    right: 12px;
    bottom: 10px;
  }
}

.product-list {
  .list-item {
    .card-wrap {
      width: 232px;
    }
  }
}

.enablehover:hover {

  .img-wrap,
  .product-type-wrap {
    border: 1px solid $secondary;
  }

  .product-type-wrap {
    background: $light-blue;
  }

  .card-title {
    color: $secondary;
  }
}

.product-card-details {
  .card-sub-label-title {
      font-size: 16px;
      font-weight: bold;
  }
}

.card-wrap-row {
  flex-direction: row !important;
  margin-bottom: 30px;

  .img-wrap {
    width: 340px;
  }

  .card-body {
    margin-left: 40px;
    cursor: pointer;

    .card-title {
      color: $secondary;
      padding: 0px 0px 8px 0px;
      font-size: 24px;
      line-height: 39px;
      font-weight: bold;
    }

    .card-sub-label {
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.form-box-wrap {
  right: 304px;
}

.form-box {
  padding: 20px 20px 30px 20px;
  background-color: $light-blue;
  box-shadow: 0px 3px 4px #00000029;

  .form-text {
    color: $primary  !important;
  }

  hr {
    color: $secondary;
    background-color: $secondary;
    height: 1px !important;
    opacity: 1;
    border-radius: 4px;
  }
}

.nav-tab-container {
  width: fit-content;

  .nav-link {
    border: none !important;
    font-size: $font-small;
    color: #747474;
  }

  .nav-link.active {
    color: $secondary  !important;
    font-weight: bold;

    .tab-count {
      font-size: $font-x-large;
      margin-top: 0;
      margin-bottom: 0 !important;
      color: $secondary  !important;
    }
  }

  .active:after {
    background-color: $secondary  !important;
  }

  .nav-item {
    position: relative;

    .nav-link {
      cursor: pointer;
    }

    .nav-link.disabled {
      cursor: not-allowed;
    }
  }

  .nav-item:not(:first-child){
    margin-left: 20px;
  }

  .tab-count {
    font-size: $font-large;
    margin-bottom: 4px !important;
    margin-top: 8px;
    color: $black;
  }
}

.form-control {
  border-radius: 4px !important;

  &:focus {
    border-color: $secondary  !important;
    box-shadow: none !important;
  }
}

.input-group {
  &:focus-within {
    border-color: $secondary  !important;
    box-shadow: none !important;
  }
}

.order-approval-link,
.order-tools {
  color: $link-blue;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

// Product card page 
.product-card {
  .wholesale-wrap {
    position: absolute;
    margin-top: 20px;
  }

  .wholesale_price,
  .retail_price {
    color: $leafio-mild-grey;
  }

  .wholesale_price {
    border-right: 1px solid $leafio-border-grey;
    width: 120px;
  }
}

.product-detail-tab {
  border-right: 1px solid $border-divider;
}


// s8 product list
.list-group-item:first-child {
  border-top: 2px solid $grey  !important;

}

.list-group {
  margin-bottom: 10px !important;

  .selected {
    background-color: $light-blue;
  }
}

.list-group-item {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;

  border: none !important;
  border-bottom: 2px solid $grey  !important;
}

.s8-products {

  .btn-primary:disabled,
  .btn-primary.disabled {
    background-color: $grey  !important;
    border-color: $grey  !important;
    color: $white  !important;
  }

  .form-control {
    border-radius: 0px !important;
    min-width: 70px !important;
    flex: unset !important;
    text-align: center !important;
  }

  .s8-name.form-control{
    border-radius: 4px !important;
    width: 100% !important;
    flex: unset !important;
    text-align: left !important;
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: $grey  !important;
  }

  .sigContainer {
    margin: 0 auto;
    border: 1px solid $grey;
    border-radius: 4px;
    background-color: $white;
    position: relative;

    .icon {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}


// Welcome page 

.welcome-wrap {
  margin-top: 100px !important;
}

// data-table-component
.datatable-wrap {
  margin-top: 10px;
}

.success-wrap {
  .success-box {
    border: 3px solid $secondary;
    height: 160px;
    width: 160px;
    margin-top: 100px;
    margin-bottom: 40px;
    border-radius: 80px;

    svg {
      color: $secondary;
      font-size: 80px;
      line-height: 100px;
      margin: auto;
    }

    p {
      font-size: 24px;
      line-height: 42px;
    }
  }
}

// date-range-picker
.date-range-wrap {
  .date-header-options {
    padding: 1px 12px;
    border: 1px solid $grey;
  }

  .react-datepicker {
    border: none;

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__header {
      border-bottom: none;
      padding: 0px;
      background-color: $white;

      .react-datepicker__day-names {
        padding: 12px 12px 0px 12px;
        margin: 0px;
      }

      .react-datepicker__day-name {
        background-color: $leafio-mild-grey;
        color: $white;
        border-radius: 2px;
      }
    }

    .react-datepicker__month-container {
      background-color: $white;
      color: $black;
      border: 1px solid $grey;
      border-radius: 4px;

      .react-datepicker__month {
        padding: 0px 12px;
        margin: 0px;
        min-height: 200px;
      }

      .react-datepicker__month-text {
        display: inline-block;
        width: 5rem;
        margin: 0.5rem;
        font-size: 1rem;
        padding: 0.2rem;

        &:hover {
          background-color: #534cea;
        }
      }

      .react-datepicker__week {

        .react-datepicker__day--keyboard-selected,
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker__quarter-text--keyboard-selected,
        .react-datepicker__year-text--keyboard-selected {
          background-color: $white;
          color: $secondary;
          border: 1px solid $secondary;
        }

        .react-datepicker__day {
          background-color: $white;
          color: $black;
          border: 1px solid $grey;
        }

        .react-datepicker__day--disabled {
          color: $date-picker-disable  !important;
        }
        .react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--today{
          border: 1px solid #ECECEC  !important;
          background-color: $white  !important;
          color: $secondary  
        }
        
        .react-datepicker__day--keyboard-selected,
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker__quarter-text--keyboard-selected,
        .react-datepicker__year-text--keyboard-selected,
        .react-datepicker__day--selected {
          background-color: $white  !important;
          color: $secondary  !important;
          font-weight: bold;
          border: 1px solid $secondary  !important;
        }
       
        .react-datepicker__day--disabled {
          color: $date-picker-disable  !important;
          border: 1px solid $grey  !important;
          }

        .react-datepicker__day--outside-month {
          background-color: $white;
          color: $black;
          border: 1px solid $grey;
        }
      }

    }
  }

}

.popover {
  max-width: unset !important;
  padding: 20px;
  border: 1px solid $secondary  !important;
  border-radius: 4px;
  z-index: unset !important;
}

.popover-arrow {
  display: none !important;
}

.text-success {
  color: $success-text;
}


.product-tab-container {
  width: fit-content;
  
  .nav-link {
    border: none !important;
    font-size: $font-x-small;
    color: #747474;
    padding-bottom: 10px;
  }

  .nav-link.active {
    color: $secondary  !important;
    font-weight: bold;
  }

  .active:after {
    background-color: $secondary  !important;
  }

  .nav-item {
    position: relative;
    .nav-link {
      cursor: pointer;
    }
    .nav-link.disabled {
      cursor: not-allowed;
    }
  }
  .nav-item:not(:first-child){
    margin-left: 32px;
  }
}

.voucher-dropdown {
  .dropdown-menu {
    right: auto;
    left: 50% !important;
    -webkit-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);

    .dropdown-item {
      background-color: transparent !important;
    }
  }
}
